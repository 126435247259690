<template>
  <div
    class="w-100"
    :class="{ 'planmode': isPlanmodeActive }"
  >
    <MapSearchParkinglot v-if="loaded && hasParkinglotLayer" />
    <MapBox
      :access-token="accessToken"
      :map-style.sync="mapStyle"
      :options="mapOptions"
      @load="onMapLoaded"
    >
      <ChargerEditorPopup v-if="isPlanmodeActive" />
      <ChargerDetailsPopup v-else />
      <ConstructionPopup />
      <TopLocationPopup />
      <TruckLocationsPopup />
      <TrafficFlowPopup />
    </MapBox>

    <Ruler />
    <MapLegend :loaded="loaded" />
    <MapLinesGenerator />

    <div class="DataSideBar">
      <MapSidebar v-if="loaded" />

      <TileDataContainer v-show="!isNeighborhoodForecast">
        <template v-if="isPdokVisible">
          <TileDataPdok :loaded="loaded" />
        </template>
        <template v-else>
          <TileData :loaded="loaded" />
        </template>
      </TileDataContainer>

      <TileDataContainer v-show="isNeighborhoodForecast">
        <template v-if="isPdokVisible">
          <TileDataPdok :loaded="loaded" />
        </template>
        <template v-else>
          <NeighborhoodData :loaded="loaded" />
        </template>
      </TileDataContainer>
    </div>

    <WhiteSpotsLayer :loaded="loaded" />
    <Milieuzones :loaded="loaded" />
    <ZeroEmissiezones :loaded="loaded" />
    <NeighborhoodsLayer :loaded="loaded" />

    <BorderLayer :loaded="loaded" />
    <FossilFuelStationsLayer :loaded="loaded" />
    <ParkNRideLayer :loaded="loaded" />
    <HoppinLayer :loaded="loaded" />
    <MiddenSpanningLayer :loaded="loaded" />
    <GrondeigendomRwsLayer :loaded="loaded" />
    <GrondeigendomProvincieLayer :loaded="loaded" />
    <TrafficFlowLayer :loaded="loaded" />
    <HighwayRestAreasLayer :loaded="loaded" />
    <VerzorgingsplaatsenRwsLayer :loaded="loaded" />
    <CarPoolParkingAreasLayer :loaded="loaded" />

    <BezoekersLocatieBundle :loaded="loaded" />
    <TruckParkingsBundle :loaded="loaded" />

    <LSLayer :loaded="loaded" />
    <LSKastenLayer :loaded="loaded" />
    <MSKastenLayer :loaded="loaded" />
    <LSMSKastenLayer :loaded="loaded" />
    <PdokCapacity :loaded="loaded" />
    <Onderstations :loaded="loaded" />

    <RegularPrognoseLayer :loaded="loaded" />
    <NeighborhoodForecastLayer :loaded="loaded" />

    <ConstructionSiteLayer :loaded="loaded" />
    <TopLocationsLayer :loaded="loaded" />

    <MraePlankaartLayer :loaded="loaded" />

    <MapDataLayers :loaded="loaded" />
    <ChargerPointsLayer :loaded="loaded" />
  </div>
</template>

<script>
// Mapbox GeoCoder
import NeighborhoodForecastLayer from '@/components/map/prognoses/NeighborhoodForecastLayer'
require('es6-promise').polyfill() // IE 11 polyfill

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import MapBox from '@/components/common/MapBox'
import MapSidebar from '@/components/map/MapSidebar'
import TileData from '@/components/map/tiles/TileData'
import TileDataPdok from '@/components/map/tiles/TileDataPdok'
import NeighborhoodData from '@/components/map/tiles/NeighborhoodData'
import Ruler from '@/components/map/Ruler.vue'
import BorderLayer from '@/components/map/national/BorderLayer'
import MiddenSpanningLayer from '@/components/map/national/MiddenSpanningLayer'
import GrondeigendomRwsLayer from '@/components/map/national/GrondeigendomRwsLayer'
import VerzorgingsplaatsenRwsLayer from '@/components/map/national/VerzorgingsplaatsenRwsLayer'
import GrondeigendomProvincieLayer from '@/components/map/regional/GrondeigendomProvincieLayer'
import TrafficFlowLayer from '@/components/map/national/TrafficFlowLayer'
import WhiteSpotsLayer from '@/components/map/national/WhiteSpotsLayer'
import Milieuzones from '@/components/map/national/Milieuzones'

import FossilFuelStationsLayer from '@/components/map/national/FossilFuelStationsLayer'
import ParkNRideLayer from '@/components/map/national/ParkNRideLayer'
import HoppinLayer from '@/components/map/regional/HoppinLayer'
import HighwayRestAreasLayer from '@/components/map/national/HighwayRestAreasLayer'
import CarPoolParkingAreasLayer from '@/components/map/national/CarPoolParkingAreasLayer'

import BezoekersLocatieBundle from '@/components/map/BezoekersLocatieBundle'
import TruckParkingsBundle from '@/components/map/TruckParkingsBundle'

import LSLayer from '@/components/map/national/LSLayer'
import LSKastenLayer from '@/components/map/national/LSKastenLayer'
import MSKastenLayer from '@/components/map/national/MSKastenLayer'
import LSMSKastenLayer from '@/components/map/national/LSMSKastenLayer'
import RegularPrognoseLayer from '@/components/map/prognoses/RegularPrognoseLayer'
import PdokCapacity from '@/components/map/national/PdokCapacity'
import Onderstations from '@/components/map/national/Onderstations'
// ZH
import TopLocationsLayer from '@/components/map/regional/TopLocationsLayer'
import ConstructionSiteLayer from '@/components/map/regional/ConstructionSiteLayer'

import MraePlankaartLayer from '@/components/map/regional/MraePlankaartLayer'

import MapDataLayers from '@/components/map/MapDataLayers'
import ChargerPointsLayer from '@/components/map/ChargerPointsLayer'
import MapSearchParkinglot from '@/components/map/MapSearchParkinglot'
import ChargerDetailsPopup from '@/components/map/modals/ChargerDetailsPopup'
import ChargerEditorPopup from '@/components/map/modals/ChargerEditorPopup'
import ConstructionPopup from '@/components/map/modals/ConstructionPopup'
import TopLocationPopup from '@/components/map/modals/TopLocationPopup'
import TruckLocationsPopup from '@/components/map/modals/TruckLocationsPopup'

import { mapGetters, mapMutations } from 'vuex'
import { StyleControl, ButtonControl } from '@/services/mapbox'
import {
  labelByCode,
  countryByMunicipalityCode,
} from '@/services/municipalities'
import { generateExport } from '@/services/pdfexport.js'
import TileDataContainer from '@/components/map/tiles/TileDataContainer'
import MapLegend from '@/components/map/MapLegend'
import MapLinesGenerator from '@/components/map/MapLinesGenerator'
import TrafficFlowPopup from '@/components/map/modals/TrafficFlowPopup'
import ZeroEmissiezones from '@/components/map/national/ZeroEmissiezones'
import NeighborhoodsLayer from '@/components/map/national/NeighborhoodsLayer'

export default {
  components: {
    ZeroEmissiezones,
    NeighborhoodsLayer,
    NeighborhoodForecastLayer,
    MapLegend,
    Ruler,
    MapLinesGenerator,
    TileDataContainer,
    MapBox, TileData, NeighborhoodData, TileDataPdok,
    BorderLayer, MiddenSpanningLayer, GrondeigendomRwsLayer, VerzorgingsplaatsenRwsLayer, GrondeigendomProvincieLayer, TrafficFlowLayer,
    FossilFuelStationsLayer, ParkNRideLayer, PdokCapacity,
    BezoekersLocatieBundle,
    Milieuzones,
    LSLayer, LSKastenLayer, MSKastenLayer, LSMSKastenLayer,
    RegularPrognoseLayer, Onderstations,
    MapDataLayers, ChargerPointsLayer, TruckParkingsBundle,
    ChargerDetailsPopup, ChargerEditorPopup, ConstructionPopup, TopLocationPopup, TruckLocationsPopup, TrafficFlowPopup,
    TopLocationsLayer, ConstructionSiteLayer, MraePlankaartLayer,
    HoppinLayer, HighwayRestAreasLayer, CarPoolParkingAreasLayer,
    MapSidebar,
    MapSearchParkinglot,
    WhiteSpotsLayer,
  },
  data() {
    return {
      loaded: false,
      geocoder: null,
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentCode', 'DeploymentConfig']),
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('config', [
      'accessToken',
      'mapStyle',
      'mapStyleSat',
      'mapOptions',
      'layers',
    ]),
    ...mapGetters('planmode', {
      isPlanmodeActive: 'isActive',
    }),
    ...mapGetters('scenarios', ['forecast']),
    ...mapGetters('layers', [
      'getForecastLayerDetails',
      'getContextLayerById',
      'getPrimaryLayers',
    ]),
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    isNeighborhoodForecast() {
      const forecastLayer = this.getForecastLayerDetails.find(
        (forecast) => forecast.id === this.forecast,
      )

      return !!forecastLayer?.config?.isNeighborhoodForecast
    },
    isPdokVisible() {
      const layer = this.getContextLayerById({ id: 'capaciteit_afname' })

      return !!layer?.visible
    },
    satUrl() {
      return this.DeploymentConfig.sat || false
    },
    country() {
      return this.DeploymentConfig.country
    },
    hasParkinglotLayer() {
      return this.getPrimaryLayers.some(
        (layer) => layer.id === 'parkinglot' && layer.visible,
      )
    },
  },
  watch: {
    /**
     * Update the geocoder country bounds
     */
    getActiveMunicipality(code) {
      let country = countryByMunicipalityCode({ code })
      if (country && this.geocoder !== null) {
        this.geocoder.setCountries(country)
      }

      this.setMapReady({ value: false })
    },
    mapOptions() {
      if (!this.$store.map) return

      if (this.mapOptions?.bounds) {
        this.$store.map.fitBounds(this.mapOptions.bounds, { padding: 20 })

        // Make the geocoder more accurate
        if (this.geocoder !== null) {
          this.geocoder.setBbox(this.mapOptions.bounds)
        }
      } else {
        this.$store.map.flyTo(this.mapOptions)
      }
    },
    hasParkinglotLayer: {
      handler(visible) {
        if (!visible) {
          this.$store.dispatch('planmode/setHighlightedParkingLots', { ids: [] })
        }
      },
    },
    isPdokVisible: 'handlePdokVisibilityChange',
    },
  beforeDestroy() {
    this.setLoaded({
      loaded: false,
    })
    this.$nextTick(() => {
      this.$store.map = null
    })
  },
  methods: {
    ...mapMutations('prognose', [
      'setLabelLayer',
      'setLoaded',
    ]),
    ...mapMutations('app', [
      'setMapReady',
    ]),
    handlePdokVisibilityChange(newVal) {
      this.isPdokVisible = newVal
    },
    onMapLoaded({ map }) {
      // Store the map in a global, non-reactive manner
      this.$store.map = map

      this.addMapControls()

      // TODO: Avoid hardcoding. The previously used label detection function no longer gives the correct result.
      this.setLabelLayer({
        id: 'waterway-label', // 'road-label'
      })

      this.loaded = true
    },
    async addMapControls() {
      const { default: mapboxgl } = await import('mapbox-gl') // Dynamically import mapbox-gl

      // Create the GeoCoder plugin
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: mapboxgl,
        countries: this.country,
        placeholder: this.$i18n.t('searchByAddress'),
      })

      // add geocoder "search bar" to the map
      this.$store.map.addControl(this.geocoder, 'top-left')

      // adds zoom control to the map
      this.$store.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
        'top-left',
      )

      // Add geolocate control to the map.
      this.$store.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        'top-left',
      )

      this.$store.map.addControl(
        new ButtonControl({
          label: 'Download Map',
          callback: this.downloadMap,
        }),
        'bottom-left',
      )

      this.$store.map.addControl(new mapboxgl.ScaleControl(), 'bottom-left')

      if (this.satUrl) {
        this.$store.map.addControl(
          new StyleControl({
            styles: [
              {
                class: 'Light',
                url: this.mapStyle,
              },
              {
                class: 'Satellite',
                url: this.mapStyleSat,
              },
            ],
          }),
          'bottom-left',
        )

        // When the style changes, trigger a reload of all layers
        this.$store.map.on('style.load', this.reloadLayers)
      }
    },
    /**
     * When the map style changes, all layers need to be reloaded
     */
    reloadLayers() {
      this.loaded = false
      this.setMapReady({ value: false })

      this.$nextTick(() => {
        this.loaded = true

        this.addAerialPhotography()
      })
    },
    /**
     * Add aerial photography
     *  WMTS layers cannot be included in mapbox styles. They need to be added with js
     */
    addAerialPhotography() {
      if (this.loaded) {
        let { sprite } = this.$store.map.getStyle()

        // If it's the SAT style that is active
        if (
          sprite
            .replace('sprites', 'styles')
            .startsWith(process.env.VUE_APP_MAPBOX_STYLE_SAT)
        ) {
          this.$store.map.addSource('aerial-photography', {
            type: 'raster',
            tiles: [this.satUrl],
            tileSize: 256,
          })
          this.$store.map.addLayer(
            {
              id: 'aerial-photography-layer',
              type: 'raster',
              source: 'aerial-photography',
              minzoom: 0,
              maxzoom: 22,
            },
            'tunnel-street-minor-low',
          )
        }
      }
    },
    /**
     * TODO: Implement Await and hint progress + add legend to output + update styling + add background to logo
     * TODO: Refactor code such that the PDF dependencies can be a seperate chunk of code (PDF modules take up about 4MB, or 3/4 of the application code... )
     */
    downloadMap(e, control) {

      control.disable({ label: this.$i18n.t('components.map.downloadMap.pdfControl') })

      setTimeout(() => {
        let title = `${this.municipalityLabel} ${this.$i18n.t('components.map.downloadMap.pdfTitle')}`
        let map = this.$store.map.getCanvas().toDataURL('image/png')

        generateExport({
          data: {
            title,
            map,
          },
          callback: () => {
            control.enable()
          },
        })
      }, 1)
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-canvas-container,
.mapboxgl-canvas {

  &:active,
  &:focus {
    outline: none;
  }
}

.mapboxgl-marker {
  position: absolute;
  cursor: pointer;
}

.StyleToggle {
  display: block;
  width: 64px;
  height: 64px;
  background: transparent;
  cursor: pointer;
  border: 1px solid black;
  background-size: 200%;

  &--Light {
    background-image: url("~@/assets/image/style-light.png");
  }

  &--Satellite {
    background-image: url("~@/assets/image/style-satellite.png");
  }
}

.DataSideBar {
  z-index: 3;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 97%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    pointer-events: all;
  }
}

.planmode #map canvas {
  cursor: crosshair;
}
</style>
