<template>
  <div class="ActiveTab ValidationTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t('components.sidebar.Tabs.CommentsTab.title') }}</h4>
    </LocatieInformatieHeader>

    <div :class="['ActiveTab__Content', { isLockedForEditing }]">
      <b-alert
        :show="isLockedForEditing"
        variant="warning"
      >
        {{ $t(isLockedForEditingMessage, { case: processCaseRef }) }}
      </b-alert>
      <TheComments :chargingpoint="chargingpoint" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import TheComments from '@/components/map/sidebar/TheComments'
import {
  EcoMovementLockedForEditingMessage,
  RHDHVBedrijventerreinenLockedForEditingMessage,
  RHDHVLockedForEditingMessage,
} from '@/services/commonMessages'
import { SOURCE } from '@/services/sourceTranslation'

export default {
  components: { TheComments, LocatieInformatieHeader },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    ...mapGetters('config', ['getPhases']),

    prio() {
      return this.chargingpoint.data.prio
    },
    customPhase () {
      return this.getPhases.find(phase => phase.uuid === this.prio?.customPhase)?.label
    },
    isBoundToProcess () {
    return !!this.chargingpoint.data.workflowCaseRef ||
           !!this.chargingpoint.data.workflowUuid ||
           !!this.chargingpoint.data.externalProcessId
    },
    isLockedForEditing () {
      return this.isBoundToProcess || this.chargingpoint.data.isLockedForEditing
    },
    processCaseRef() {
      return this.chargingpoint.data?.workflowCaseRef || this.chargingpoint.data?.externalProcessId
    },
    isLockedForEditingMessage () {
      if (this.isBoundToProcess) {
        return 'components.sidebar.Tabs.CommentsTab.editNotAllowed'
      }
      const isEcoMovementLocation = this.chargingpoint.data.address?.source === SOURCE.ECO_MOVEMENT || this.chargingpoint.data.meta?.source === SOURCE.ECO_MOVEMENT || false
      if (isEcoMovementLocation) {
        return EcoMovementLockedForEditingMessage
      }

      // TOOLS-3285: Vastzetten palen met fase "Bedrijventerreinen GORAL"
      if (this.customPhase === 'Bedrijventerreinen GORAL') {
        return RHDHVBedrijventerreinenLockedForEditingMessage
      }

      return RHDHVLockedForEditingMessage
    },
  },
}
</script>

<style lang="scss">
.ActiveTab__Content {
  p {
    margin-bottom: .5rem
  }
}
</style>
