<template>
  <div class="FeaturePanel d-flex">
    <aside class="FeaturePanel__Aside flex-shrink-0 mr-3">
      <h3 class="FeaturePanel__Title">
        {{ $t('featureForecastPanel.featurePanel.title') }}
      </h3>
      <div class="w-75 mt-3">
        <p>
          {{ $t('featureForecastPanel.featurePanel.description') }}
        </p>
      </div>
    </aside>

    <div class="FeaturePanel__Main flex-shrink-1">
      <div
        v-for="toggle in toggles"
        :key="toggle.name"
        class="d-flex align-items-center mb-4"
      >
        <span
          class="FeaturePanel__Label mr-3"
          v-text="toggle.name"
        />

        <ToggleSwitch
          :disabled="toggle.disabled"
          :value="toggle.value !== undefined ? toggle.value : data[toggle.key]"
          @input="event => handleToggleInput({ toggle, event })"
        />

        <span
          v-if="toggle.hint"
          class="ml-3 text-muted"
          v-text="toggle.hint"
        />

        <div
          v-if="toggle.tooltip"
          class="ml-auto"
        >
          <SvgIcon
            :id="toggle.key"
            icon="info-circle-regular"
          />
          <b-tooltip
            :target="toggle.key"
            triggers="hover"
          >
            {{ $t(toggle.tooltip) }}
          </b-tooltip>
        </div>
      </div>

      <div class="d-flex align-items-center mt-4 mr-4 multi-select">
        <span class="PrognosePanel__Label mr-3">
          {{ $t('featureForecastPanel.featurePanel.phases.title') }}
        </span>
        <div class="phase-select">
          <vSelect
            ref="fasesSelect"
            :value="activePhases"
            multiple
            taggable
            uid
            :disabled="selectDisabled"
            :description="$t('featureForecastPanel.featurePanel.phases.description')"
            :options="passivePhases"
            :is-c-r-u-d="true"
            :autofocus="true"
            :menu-actions="phaseMenuActions"
            class="flex-grow-1 mr-3"
            :select-group-label="$t('components.form.select.select-groupLabels')"
            @option:selecting="handleAddItem"
            @option:deselecting="handleDeactivateItem"
            @option:created="handleCreateNewItem"
            @delete-item="handleDeleteItem"
            @deactivate-item="handleDeactivateItem"
            @rename-item="handleRenameItem"
            @select-disabled="selectDisabled = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ToggleSwitch from '@/components/form/ToggleSwitch'
import SvgIcon from '@/components/common/SvgIcon'
import vSelect from '@/components/form/vSelect'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'FeaturePanel',
  components: { SvgIcon, ToggleSwitch, vSelect },
  mixins: [chargingpointsLoadMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
    hasLayers: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      data: {
        prognose: true,
        planmode: true,
        upload: true,
        factsheet: false,
        bedrijventerreinen: false,
        export: false,
        phases: [],
        isVoltLocked: true,
        hasVoltLocations: false,
      },
      trackChanges: true,
      filteredPhaseOptions: [],
      phaseFieldDescription: this.$i18n.t('featureForecastPanel.featurePanel.phases.description'),
      initialDataValues: null,
      activePhases: [],
      passivePhases: [],
      phaseMenuActions: {
        deactivate: {
          label: this.$i18n.t('featureForecastPanel.featurePanel.phases.deactivate'),
          icon: 'close-light',
          isButton: true,
        },
        rename: {
          label: this.$i18n.t('featureForecastPanel.featurePanel.phases.rename'),
          icon: 'pencil',
          isButton: false,
          hasFormInput: true,
        },
        delete: {
          label: this.$i18n.t('featureForecastPanel.featurePanel.phases.delete'),
          icon: 'trash-alt-regular',
          isButton: true,
          possibleLock: true,
        },
      },
      selectDisabled: false,
      difference: false,
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('config', ['config']),
    ...mapGetters('planmode', ['getChargingPoints', 'getLocationSources']),

    toggles () {
      return [
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.forecast'),
          disabled: !this.hasLayers,
          key: 'prognose',
          hint: this.$i18n.t('featureForecastPanel.featurePanel.required.layers'),
        },
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.planMap'),
          disabled: !this.data.prognose || !this.hasLayers,
          key: 'planmode',
          // The planmode is dependent on the prognose feature, so it is automatically disabled when the prognose feature is disabled
          value: this.data.prognose === false ? false : this.data.planmode,
          hint: this.$i18n.t('featureForecastPanel.featurePanel.required.forecast'),
          tooltip: this.$i18n.t('featureForecastPanel.featurePanel.tooltip.forecastDependent'),
        },
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.uploadPortal'),
          key: 'upload',
        },
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.factsheet'),
          key: 'factsheet',
        },
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.businessParks'),
          key: 'bedrijventerreinen',
          hint: '(Factsheet)',
        },
        {
          name: this.$i18n.t('featureForecastPanel.featurePanel.chargingpointExport'),
          key: 'export',
        },
        ...(
          this.hasConfigVoltLockedKey || this.data.hasVoltLocations ?
          [{
            name: `VOLT ${this.$i18n.tc('chargingPoints', 2).toLowerCase()} ${this.$i18n.t('toLock').toLowerCase()}`,
            key: 'isVoltLocked',
            tooltip: this.$i18n.t('featureForecastPanel.featurePanel.tooltip.voltLock'),
          }] : []
        ),
      ]
    },
    hasConfigVoltLockedKey () {
      return Object.hasOwn(this.config, 'isVoltLocked')
    },
  },
  watch: {
    /**
     * When any input changes, pass it on
     */
    data: {
      handler(data) {
        this.activePhases = data.phases.filter(phase => phase.active)
        this.passivePhases = data.phases.filter(phase => !phase.active)

        this.difference = this.initialDataValues ? JSON.stringify(data) !== JSON.stringify(this.initialDataValues) : false

        if (!this.data.hasVoltLocations) {
          delete this.data.isVoltLocked
        }

        this.$emit('input', { data, difference: this.difference })
      },
      deep: true,
    },
    config: {
      immediate: true,
      async handler (config) {
        this.data.hasVoltLocations =
          this.hasConfigVoltLockedKey ||
          await this.chargingpointsBySource({ code: config.code, source: 'import-royal-haskoning' }) > 0

        this.setValues(true)
      },
    },
  },
  methods: {
    /**
     * Set prop values as new data, without signaling input changes
     */
    async setValues(initial) {
      this.data.phases = this.config.phases ? JSON.parse(JSON.stringify(this.config.phases)) : []

      if (initial) {
        this.data.isVoltLocked = !this.hasConfigVoltLockedKey ? this.data.hasVoltLocations : this.config.isVoltLocked
        this.initialDataValues = JSON.parse(JSON.stringify(this.data))
      }

      // Add lockedForDelete prop to a phase to prevent deleting of the phase if it's used on location //
      if (this.data.phases?.length && !this.getChargingPoints.length) {
        await this.$_chargingpointsLoadMixin_loadChargingPoints({
          code: this.getActiveMunicipality,
        })
      }

      this.setInitialPhases(initial)
    },
    handleToggleInput ({ toggle, event }) {
      this.data[toggle.key] = event

      this.difference = !this.difference
      this.$emit('input', { data: this.data, difference: this.difference })
    },
    setInitialPhases (initial) {
      this.data.phases.map(phase => {
        phase.chargingpointsCount = this.getChargingPoints.filter(chargingpoint => chargingpoint.data.prio?.customPhase === phase.uuid).length
        phase.isLockedForDelete = !!phase.chargingpointsCount
      })

      if (initial) {
        this.initialDataValues = JSON.parse(JSON.stringify(this.data))
      }
    },
    handleCreateNewItem (event) {
      const label = event.label || event
      if (this.data.phases.some(phase => phase.label === label)) return

      this.data.phases.push({ label, active: true })
      // Set focus on input after creating a new phase //
      this.$nextTick(() => {
        this.$refs.fasesSelect.$children?.[0]?.$refs?.search?.focus()
      })
    },
    handleAddItem (event) {
      const index = this.data.phases.findIndex(phase => phase.uuid === event.uuid)
      if (index !== -1) {
        this.data.phases[index].active = true
      }
    },
    handleRenameItem ({ selectedItem, newItemLabel }) {
      const index = this.data.phases.findIndex(phase => phase.uuid === selectedItem.uuid)
      if (index !== -1) {
        if (newItemLabel) {
          this.data.phases[index].label = newItemLabel
        }
      }
    },
    handleDeactivateItem ({ selectedItem }) {
      const index = this.data.phases.findIndex(phase => phase.label === selectedItem.label)
      this.data.phases[index].active = false
    },
    handleDeleteItem ({ selectedItem }) {
      if (selectedItem.isLockedForDelete) return

      const index = this.data.phases.findIndex(phase => phase.label === selectedItem.label)
      this.data.phases.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
.FeaturePanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;
    flex: 1;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
    &#phases {
      margin-bottom: 1em;
    }
  }
  .multi-select {
    position: relative;

    .phase-select {
      flex: 0 1 100%;
      .dropdown {
        transform: translateY(-50px);
        width: calc(100% - 1em);
        .dropdown-menu {
          width: 100%;
        }
        .dropdown-toggle {
          pointer-events: none;
        }
      }
    }

  }
}
</style>
