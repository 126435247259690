<template>
  <CollapsableSegment
    :label="label"
    class="LegendSegment mb-3"
  >
    <template #header>
      <ToggleSwitch
        v-model="all"
        class="m-0"
      />
    </template>

    <ul class="m-0 p-0 list-unstyled">
      <LegendItem
        v-for="layer in primaryLayers"
        :key="layer.id"
        :layer="layer"
        :value="layer.visible"
        @input="(value) => handleInput({layer, value})"
      />
    </ul>
    <CollapsableSubSegment :visible="false">
      <ul class="m-0 p-0 list-unstyled">
        <LegendItem
          v-for="layer in secondaryLayers"
          :key="layer.id"
          :layer="layer"
          :value="layer.visible"
          @input="(value) => handleInput({layer, value})"
        />
      </ul>
    </CollapsableSubSegment>
  </CollapsableSegment>
</template>

<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import CollapsableSubSegment from '@/components/common/CollapsableSubSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import LegendItem from '@/components/sidebar/LegendItem'

import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'

import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'LegendSegment',
  components: {
    CollapsableSegment, CollapsableSubSegment, ToggleSwitch, LegendItem,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentConfig',
    ]),
    ...mapGetters('config', {
      configLayers: 'layers',
    }),
    ...mapGetters('app', [
      'isMapReady',
    ]),
    ...mapGetters('currentUser', {
      currentUserRole: 'getCurrentUserRole',
      superuser: 'isSuperuser',
      admin: 'isAdmin',
    }),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    ...mapGetters('layers', {
      getLayerDetailsById: 'getLayerDetailsById',
      dataLayers: 'getDataLayerDetails',
      activeLayers: 'getActiveLayers',
      primaryLayers: 'getPrimaryLayers',
      secondaryLayers: 'getSecondaryLayers',
      excludedLayers: 'getExcludedLayers',
    }),
    /**
     * Watch the all layers on / off toggle.
     */
    all: {
      get() {
        return !this.primaryLayers.some(layer => !layer.visible)
      },
      set(value) {
        this.primaryLayers.forEach(layer => layer.visible = value)
        this.setActiveLayersVisibility()
      },
    },
  },
  watch: {
    /**
     * Re-load the legend config when the municipality config changes
     *  And apply the toggle states to the newly added layers
     *  (All layers have to be removed & re-added on munilipality change)
     * Triggers when the MapBox instance is created and ready, or after it flew anywhere to,
     * or is re-created after coming from another route like admin/factsheet etc...
     */
    configLayers() {
      this.initiateLayers()
      this.setActiveLayersVisibility()
    },
    /**
     * Triggers when the MapBox instance is created and ready, or after it flew anywhere to,
     * or is re-created after coming from another route like admin/factsheet etc...
     */
    isMapReady() {
      this.setActiveLayersVisibility()
    },
  },
  created() {
    this.initiateLayers()

    EventBus.$on('layers.national.loaded', this.handleNationalLayerLoaded)
  },
  beforeDestroy() {
    EventBus.$off('layers.national.loaded', this.handleNationalLayerLoaded)
  },
  methods: {
    ...mapActions('layers', [
      'storeContextLayers',
      'addLayerToInMapLegend',
      'removeLayerFromInMapLegend',
    ]),

    determineLayerStatus({ layer }) {
      // if context layer
      if (this.configLayers[layer.id]?.status) {
        return this.configLayers[layer.id].status
      }

      if (layer.type === 'national') {
        return layer.status
      }

      return 'hidden'
    },

    /**
     * Apply the layer config from the selected municipality
     */
    initiateLayers() {
      this.storeContextLayers({
        layers: this.dataLayers
          .filter(layer => ! this.excludedLayers.includes(layer.id))
          .map(layer => {
            layer.status = this.determineLayerStatus({ layer })
            layer.visible = layer.status === 'primary'
            return layer
          }),
      })
    },

    /* handle each toggle click on its own */
    handleInput({ layer, value }) {
      layer.visible = value

      const additionalLayers = layer.layers ?? []

      // add all the sub layers, so they will be toggled in mapbox as well
      const layers = [
        layer,
        ...additionalLayers.map((id) => ({
          // add layer information
          ...this.getLayerDetailsById({ id }),
          // give same visibility
          visible: value,
        }))
          // only layers that are defined in layerDetails.js
          .filter(value => !!value.id),
      ]

      layers.forEach(this.toggleLayer)
    },
    /**
     * Apply the visibility state to the Mapbox layers
     *  This is triggered on any change of the toggles
     *  as well as a switch to a different municipality
     */
    setActiveLayersVisibility() {
      // All primary layers with their sublayers need to be toggled
      const primaryLayers = this.activeLayers.filter(layer => layer.status === 'primary')
      primaryLayers.forEach(layer => this.handleInput({ layer, value: layer.visible  }))

      this.activeLayers.forEach(layer => {
        if (layer.status === 'secondary') {
          this.toggleLayer({ id: layer.id, visible: layer.visible })
        }
      })
    },
    /**
     * Toggle the visibility state of a specific MapBox layer
     */
    toggleLayer({ id, visible }) {
      if (this.isMapReady && this.$store.map.getLayer(id)) {
        const state = visible ? 'visible' : 'none'
        this.$store.map.setLayoutProperty(id, 'visibility', state)
      }

      if (this.$store.map.getLayer(id + '-text')) {
        const state = visible ? 'visible' : 'none'
        this.$store.map.setLayoutProperty(id + '-text', 'visibility', state)
      }

      if (visible) {
        this.addLayerToInMapLegend({ layerId: id })
      } else {
        this.removeLayerFromInMapLegend({ layerId: id })
      }
    },
    /**
     * Update the layer visibility as soon as the layer has been loaded
     */
    handleNationalLayerLoaded({ name }) {
      let layer = this.activeLayers.find(layer => layer.id === name)
      if (layer) {
        this.toggleLayer({
          id: name,
          visible: layer.visible,
          layer,
        })
      }
    },
  },
}
</script>
