<template>
  <div class="editor-container">
    <vue-editor
      :id="text"
      v-model="content"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

  <script>
  import { VueEditor } from 'vue2-quill-editor'

  export default {
    name: 'QuillEditor',
    components: { VueEditor },
    props: {
      initialContent: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      // eslint-disable-next-line vue/require-default-prop
      index: {
        type: Number,
      },
    },
    data() {
      return {
        content: this.initialContent,
        customToolbar: [
          [{ header: [1, 2, 3, false] }], // Header dropdown
          ['bold', 'italic', 'underline'], // Text formatting
          ['blockquote'], // Block styles
          [{ list: 'ordered' }, { list: 'bullet' }], // Lists
          [{ indent: '-1' }, { indent: '+1' }], // Indentation
          [{ direction: 'rtl' }], // Text direction
          [{ color: [] }, { background: [] }], // Text color/background color
          [{ font: [] }], // Font family
          [{ align: [] }], // Text alignment
          ['link'], // Links
          ['clean'], // Remove formatting
        ],
      }
    },
    watch: {
      initialContent(newValue) {
        this.content = newValue
      },
      content(newValue) {
        this.$emit('content-changed', { text: this.text, content: newValue, index: this.index })
      },
    },
  }
  </script>

<style lang="scss">
  .editor-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    margin-right: 12px;
    padding: 1em;
    border: 1px solid lightgray;
    border-radius: 8px;
    background-color: white;

    .ql-toolbar {
      background-color: #F0F0F0;
    }

    .ql-container {
      max-height: 300px;
      overflow-y: auto;
    }

  }
</style>
